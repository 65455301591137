import _ from 'underscore';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import EntityPropTypes from 'propTypes/entities';

import BaseSlider from 'components/sliders/BaseSlider';
import PublicationCard from 'components/cards/entities/PublicationCard';
import PromoBlockCard from 'components/cards/entities/PromoBlockCard';

import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

const NewPublications = ({ publications }) => {
	const sliderParams = useMemo(
		() => ({
			slidesPerView: 'auto',
			grid: {
				rows: 2
			},
			slidesOffsetBefore: 8,
			spaceBetween: 0,
			breakpoints: {
				[SCREEN_BREAKPOINTS.mobileMedium]: {
					slidesPerView: 2,
					grid: {
						rows: 2
					},
					spaceBetween: 8,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0
				},
				[SCREEN_BREAKPOINTS.mobileLarge]: {
					slidesPerView: 4,
					grid: {
						rows: 1
					},
					spaceBetween: 16,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0
				},
				[SCREEN_BREAKPOINTS.tabletMedium]: {
					slidesPerView: 4,
					grid: {
						rows: 1
					},
					spaceBetween: 16,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0
				},
				[SCREEN_BREAKPOINTS.tabletLarge]: {
					slidesPerView: 4,
					grid: {
						rows: 1
					},
					spaceBetween: 24,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0
				}
			}
		}),
		[]
	);
	const imageParams = { width: 392, height: 282 };

	return (
		<BaseSlider theme="mobileMultirow" params={sliderParams}>
			{_(publications).map((publication) =>
				publication.type === 'card' ? (
					<PromoBlockCard
						key={publication._id}
						entity={publication}
						params={imageParams}
						target="_blank"
					/>
				) : (
					<PublicationCard
						key={publication._id}
						entity={publication}
						theme="cover"
						params={imageParams}
					/>
				)
			)}
		</BaseSlider>
	);
};

NewPublications.propTypes = {
	publications: PropTypes.arrayOf(EntityPropTypes.publication).isRequired
};

export default NewPublications;
